@import 'src/scss/styles';

.navigationWrapper {
  display: flex;
  align-items: center;
  position: fixed;
  height: 52px;
  width: 100%;
  min-width: 1000px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.12);
  border-bottom: 1px solid lighten(#202020, 5%);
  z-index: 2;
  background: #202020;
}

.navigationContainer {
  display: flex;
  align-items: center;
  max-width: 1250px;
  width: 100%;
  margin: auto;
}

.stylist {
  font-family: 'Raleway', sans-serif;
  color: #e3dddb;
  font-weight: 600;
  font-size: 14px;
  margin-left: 20px;
}

.navContact {
  font-family: 'Raleway', sans-serif;
  text-transform: uppercase;
  color: #e3dddb;
  font-weight: 600;
  font-size: 14px;
  margin-left: 20px;
}

.flexGrow {
  flex-grow: 1;
}

.navigationLink {
  font-family: 'Raleway', sans-serif;
  text-transform: uppercase;
  color: #e3dddb;
  cursor: pointer;
  padding: 0px 20px;
  font-size: 12px;
  letter-spacing: 1px;
  align-items: stretch;
  font-weight: 700;
}

.navigationLink:hover {
  color: lighten(#e3dddb, 10%);
}