@import url(https://fonts.googleapis.com/css?family=Lato:300,400&display=swap);
@import url(https://fonts.googleapis.com/css?family=Raleway:500,600,700,800&display=swap);
html,
body,
#root {
  height: 100%;
  min-width: 1100px;
  font-family: 'Lato', sans-serif;
  margin: 0px; }

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 5px 0px; }

a {
  color: #3e96ed;
  font-weight: 300;
  font-size: 13px;
  text-decoration: none; }

ul.leaders {
  padding: 0;
  overflow-x: hidden;
  list-style: none; }

ul.leaders li {
  margin-top: 5px;
  font-family: 'Raleway', sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 1.5;
  color: #202020; }

ul.leaders li:before {
  float: left;
  width: 0;
  white-space: nowrap;
  content: '. . . . . . . . . . . . . . . . . . . . ' '. . . . . . . . . . . . . . . . . . . . ' '. . . . . . . . . . . . . . . . . . . . ' '. . . . . . . . . . . . . . . . . . . . ' '. . . . . . . . . . . . . . . . . . . . '; }

ul.leaders span:first-child {
  padding-right: 0.33em;
  background: white; }

ul.leaders span + span {
  float: right;
  padding-left: 0.33em;
  background: white; }

.NotFound_notFoundWrapper__283SL {
  display: flex;
  flex: 1 1 100%;
  width: 100%;
  padding: 50px 15px 15px 15px;
  justify-content: center;
  color: #444; }

.NotFound_notFoundContainer__2ejdN {
  padding: 0px 25px;
  max-width: 400px; }

.NotFound_notFoundContainer__2ejdN h1 {
  font-weight: 400;
  font-size: 75px; }

.NotFound_notFoundContainer__2ejdN p {
  margin: 10px 0px;
  font-size: 15px; }

.Navigation_navigationWrapper__1uM17 {
  display: flex;
  align-items: center;
  position: fixed;
  height: 52px;
  width: 100%;
  min-width: 1000px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.12);
  border-bottom: 1px solid #2d2d2d;
  z-index: 2;
  background: #202020; }

.Navigation_navigationContainer__1juLl {
  display: flex;
  align-items: center;
  max-width: 1250px;
  width: 100%;
  margin: auto; }

.Navigation_stylist__197KO {
  font-family: 'Raleway', sans-serif;
  color: #e3dddb;
  font-weight: 600;
  font-size: 14px;
  margin-left: 20px; }

.Navigation_navContact__2zRKb {
  font-family: 'Raleway', sans-serif;
  text-transform: uppercase;
  color: #e3dddb;
  font-weight: 600;
  font-size: 14px;
  margin-left: 20px; }

.Navigation_flexGrow__25O2J {
  flex-grow: 1; }

.Navigation_navigationLink__10qPO {
  font-family: 'Raleway', sans-serif;
  text-transform: uppercase;
  color: #e3dddb;
  cursor: pointer;
  padding: 0px 20px;
  font-size: 12px;
  letter-spacing: 1px;
  align-items: stretch;
  font-weight: 700; }

.Navigation_navigationLink__10qPO:hover {
  color: #f9f8f8; }

.Loading_loading__3-t_e {
  z-index: 9999;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0; }

.Loading_loading__3-t_e.Loading_dark__HxRb3 {
  background-color: rgba(0, 0, 0, 0.5); }

.Loading_loading__3-t_e.Loading_light__nfPIn {
  background-color: transparent; }

.Loading_spinner__3N8VB {
  animation: Loading_spin__3VhC0 1.75s linear infinite;
  height: 45px;
  width: 45px; }

@keyframes Loading_spin__3VhC0 {
  100% {
    transform: rotate(360deg); } }

