html,
body,
#root {
  height: 100%;
  min-width: 1100px;
  font-family: 'Lato', sans-serif;
  margin: 0px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 5px 0px;
}

a {
  color: #3e96ed;
  font-weight: 300;
  font-size: 13px;
  text-decoration: none;
}
ul.leaders {
  padding: 0;
  overflow-x: hidden;
  list-style: none;
}
ul.leaders li {
  margin-top: 5px;
  font-family: 'Raleway', sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 1.5;
  color: #202020;
}

ul.leaders li:before {
  float: left;
  width: 0;
  white-space: nowrap;
  content: '. . . . . . . . . . . . . . . . . . . . '
    '. . . . . . . . . . . . . . . . . . . . '
    '. . . . . . . . . . . . . . . . . . . . '
    '. . . . . . . . . . . . . . . . . . . . '
    '. . . . . . . . . . . . . . . . . . . . ';
}

ul.leaders span:first-child {
  padding-right: 0.33em;
  background: white;
}

ul.leaders span + span {
  float: right;
  padding-left: 0.33em;
  background: white;
}
